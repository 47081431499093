<template>
  <div>
    <canvas id="circleCanvas"></canvas>
  </div>
</template>
<script>
export default {
  name: "Home",
  mounted() {

    const canvas = document.getElementById("circleCanvas");
    const context = canvas.getContext('2d');

    canvas.width = 800;
    canvas.height = 800;

    const circles = [];

    function createCircles() {
      const radiusIncrement = 5;
      const angleIncrement = 40;

      let currentRadius = 4;

      for (let i = 0; i < 12; i++) {
        const borderWidth = Math.floor(Math.random() * 6) + 1;
        const speed = Math.random() * 0.5 + 0.5;

        const circle = {
          x: canvas.width / 2 + Math.cos(i * angleIncrement * Math.PI / 180) * 260,
          y: canvas.height / 2 + Math.sin(i * angleIncrement * Math.PI / 180) * 260,
          radius: currentRadius,
          borderWidth: borderWidth,
          angle: i * angleIncrement,
          speed: speed <= 0 ? 1 : speed,
        };

        circles.push(circle);
        currentRadius += radiusIncrement;
      }
    }

    function drawCircles() {
      context.clearRect(0, 0, canvas.width, canvas.height);

      for (var i = 0; i < circles.length; i++) {
        var circle = circles[i];

        // Oblicz nową pozycję okręgu na podstawie kąta i prędkości
        circle.x = canvas.width / 2 + Math.cos(circle.angle * Math.PI / 180) * 260;
        circle.y = canvas.height / 2 + Math.sin(circle.angle * Math.PI / 180) * 260;

        // Zmieniaj promień impulsywnie
        circle.radius -= Math.sin(circle.angle * Math.PI / 180) * 0.2;

        // Rysuj okrąg z efektem glitch noise
        if (circle.radius > 0) {
          let radius = circle.radius;
          if (radius < 1) radius = 1; // Minimalny promień, aby uniknąć błędu getImageData

          const gradientDirection = (i % 2 === 0) ? 'horizontal' : 'vertical';

          let gradient;
          if (gradientDirection === 'horizontal') {
            gradient = context.createLinearGradient(circle.x, circle.y - circle.radius, circle.x, circle.y + circle.radius);
          } else {
            gradient = context.createLinearGradient(circle.x - circle.radius, circle.y, circle.x + circle.radius, circle.y);
          }
          // var gradient = context.createLinearGradient(circle.x - circle.radius, circle.y, circle.x + circle.radius, circle.y);
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.05)'); // Końcowy kolor z mniejszą przezroczystością
          gradient.addColorStop(0.4, 'rgba(255, 255, 255, 0.05)'); // Końcowy kolor z mniejszą przezroczystością
          gradient.addColorStop(0.5, 'rgba(1, 233, 233, 0.1)'); // Początkowy kolor przezroczysty
          gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0.05)'); // Końcowy kolor z mniejszą przezroczystością
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0.05)'); // Końcowy kolor z mniejszą przezroczystością

          // Zastosuj efekt glitch noise
          const imageData = context.getImageData(circle.x - radius, circle.y - radius, radius * 2, radius * 2);
          const data = imageData.data;

          for (let j = 0; j < data.length; j += 4) {
            // Mieszanie kanałów RGB
            data[j] = data[j + 1] ^ data[j + 2];
            data[j + 1] = data[j + 0] ^ data[j + 2];
            data[j + 2] = data[j + 0] ^ data[j + 1];

            // Dodanie szumów
            var noise = (Math.random() * 2 - 1) * 20;
            data[j] += noise;
            data[j + 1] += noise;
            data[j + 2] += noise;
          }

          context.putImageData(imageData, circle.x - radius, circle.y - radius);

          context.beginPath();
          context.arc(circle.x, circle.y, circle.radius, 0, 2 * Math.PI);
          context.lineWidth = circle.borderWidth;
          context.strokeStyle = gradient;
          context.stroke();
        }

        // Zwiększ kąt o prędkość
        circle.angle += circle.speed;
      }

      requestAnimationFrame(drawCircles); // Wywołaj funkcję rysującą w każdej klatce animacji
    }

    function startAnimation() {
      createCircles();
      drawCircles();
    }
    this.$nextTick(startAnimation);

    // this.glitchImage();


    const Glitcher = function (options) {
      this.canvas = document.createElement('canvas');
      this.context = this.canvas.getContext('2d');
      this.origCanvas = document.createElement('canvas');
      this.origContext = this.origCanvas.getContext('2d');
      this.options = options;
    };

    Glitcher.prototype.glitch = function (url, callback) {
      const _this = this;
      this.loadImage(url, function (img) {
        _this.renderImage(img);
        _this.process();
        callback();
      });
    };

    Glitcher.prototype.process = function () {
      const imageData = this.origContext.getImageData(0, 0, this.width, this.height);
      const pixels = imageData.data;
      const length = pixels.length;
      const options = this.options;
      let brightness, offset, i, x, y;

      for (i = 0; i < length; i += 4) {
        if (options.color) {
          pixels[i] *= options.color.red;
          pixels[i + 1] *= options.color.green;
          pixels[i + 2] *= options.color.blue;
        }

        if (options.greyscale) {
          brightness =
              pixels[i] * options.greyscale.red +
              pixels[i + 1] * options.greyscale.green +
              pixels[i + 2] * options.greyscale.blue;

          pixels[i] = brightness;
          pixels[i + 1] = brightness;
          pixels[i + 2] = brightness;
        }

        if (options.stereoscopic) {
          offset = options.stereoscopic.red;
          pixels[i] = pixels[i + 4 * offset] === undefined ? 0 : pixels[i + 4 * offset];

          offset = options.stereoscopic.green;
          pixels[i + 1] = pixels[i + 1 + 4 * offset] === undefined ? 0 : pixels[i + 1 + 4 * offset];

          offset = options.stereoscopic.blue;
          pixels[i + 2] = pixels[i + 2 + 4 * offset] === undefined ? 0 : pixels[i + 2 + 4 * offset];
        }
      }

      if (options.lineOffset) {
        i = 0;

        for (y = 0; y < this.height; y++) {
          offset = y % options.lineOffset.lineHeight === 0 ? Math.round(Math.random() * options.lineOffset.value) : offset;

          for (x = 0; x < this.width; x++) {
            i += 4;
            pixels[i + 0] = pixels[i + 4 * offset] === undefined ? 0 : pixels[i + 4 * offset];
            pixels[i + 1] = pixels[i + 1 + 4 * offset] === undefined ? 0 : pixels[i + 1 + 4 * offset];
            pixels[i + 2] = pixels[i + 2 + 4 * offset] === undefined ? 0 : pixels[i + 2 + 4 * offset];
          }
        }
      }

      if (options.glitch) {
        // Kod obsługujący efekt glitcha
      }

      this.context.putImageData(imageData, 0, 0);
    };

    Glitcher.prototype.loadImage = function (url, callback) {
      const img = document.createElement('img');
      img.crossOrigin = 'anonymous';
      img.onload = function () {
        callback(img);
      };
      img.src = url;
    };

    Glitcher.prototype.renderImage = function (img) {
      this.canvas.width = this.origCanvas.width = this.width = img.width;
      this.canvas.height = this.origCanvas.height = this.height = img.height;

      this.origContext.drawImage(img, 0, 0);
    };

    const glitcher = new Glitcher({
      color: {
        red: 1,
        green: 1,
        blue: 1,
      },
      stereoscopic: {
        red: 1,
        green: 10,
        blue: 2,
      },
      lineOffset: {
        value: 4,
      },
    });

    const url = require('@/assets/avatar_ws.png');
    glitcher.glitch(url, function () {
      document.body.appendChild(glitcher.canvas);
    });

    function randomRange(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    setInterval(function () {
      glitcher.options = {
        color: {
          red: 1,
          green: 0.8,
          blue: 0.58,
        },
        stereoscopic: {
          red: 1 * randomRange(1, 1),
          green: 2 * randomRange(1, 2),
          blue: 1 * randomRange(1, 2),
        },
        lineOffset: {
          value: 1 * randomRange(1, 2),
          lineHeight: 3 * randomRange(1, 2),
        },
      };
      glitcher.process();
    }, 100);
  },
  methods: {
    startAnimation() {
    },

  }
};
</script>


<style>
* {
  margin: 0;
  padding: 0;
}

body {
  background: #0e191e;
  font-size: 20px;
  font-weight: 300;
}

canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}


#container {
  position: relative;
  width: 360px;
  height: 360px;
}

#circleCanvas {
  transform: translate(0, -50px);
}
</style>